<template>
  <div>
    <div class="mb-3">
      <Breadcrumbs :items="items" />
    </div>
    <v-form
      class="multi-col-validation mt-6"
    >
      <v-card id="account-setting-card">
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-card-text>
          <v-row>
            <!-- FirstName -->
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="accountSettingData.firstName"
                label="Nombre(s)"
                dense
                outlined
                :rules="[
                  (v) => !!v || 'El campo Nombres es obligatorio',
                ]"
              >
                <template #label>
                  <app-label
                    field="Nombre(s)"
                    required="true"
                  />
                </template>
              </v-text-field>
            </v-col>
            <!-- LastName -->
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="accountSettingData.lastName"
                label="Apellidos"
                dense
                outlined
                :rules="[
                  (v) => !!v || 'El campo Apellidos es obligatorio',
                ]"
              >
                <template #label>
                  <app-label
                    field="Apellidos"
                    required="true"
                  />
                </template>
              </v-text-field>
            </v-col>
            <!-- UserName -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="accountSettingData.username"
                label="Usuario"
                dense
                outlined
                :rules="[
                  (v) => !!v || 'El campo Usuario es obligatorio',
                ]"
              >
                <template #label>
                  <app-label
                    field="Usuario"
                    required="true"
                  />
                </template>
              </v-text-field>
            </v-col>
            <!-- Email -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="accountSettingData.email"
                label="Correo"
                dense
                outlined
                :rules="[
                  (v) => !!v || 'El campo Correo es obligatorio',
                ]"
              >
                <template #label>
                  <app-label
                    field="Corrreo"
                    required="true"
                  />
                </template>
              </v-text-field>
            </v-col>

            <!-- phone -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="accountSettingData.phone"
                label="Teléfono"
                outlined
                dense
                placeholder="(09) 99999999"
                error-count="2"
                :error="campos.phone.error"
                :error-messages="errorMsgPhone"
              ></v-text-field>
            </v-col>

            <!-- Area -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="accountSettingData.area"
                label="Área"
                outlined
                dense
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            class="mt-3"
            color="secondary"
            type="reset"
            @click.prevent="resetForm"
          >
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiCalendar,
  mdiAlertOutline,
  mdiCloudUploadOutline,
} from '@mdi/js'
import HTTP from '@/utils/axios/axios-config-base'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: {
    Breadcrumbs,
    AppLabel,
  },
  setup() {
    const { router } = useRouter()
    const formTitulo = ref('Mi Perfil')
    const keyComponnent = ref(0)
    const loading = ref(false)
    const loadingAreas = ref(false)
    const listaAreas = reactive([])
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Mi Perfil',
        disabled: true,
        href: 'pages/account-settings',
      },
    ])

    // tabs
    const tabs = [
      { title: 'Datos Generales', icon: mdiAccountOutline },
      { title: 'Configuración', icon: mdiInformationOutline },

      // { title: 'Security', icon: mdiLockOpenOutline },
    ]
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    const tiposIdentificacion = [
      { id: 'Ruc', name: 'Ruc' },
      { id: 'CI', name: 'CI' },
      { id: 'Passport', name: 'Pasaporte' },
    ]
    const genero = [
      { id: 'male', name: 'Masculino' },
      { id: 'female', name: 'Femenino' },
    ]
    const estadoCivil = [
      { id: 'Single', name: 'Soltero' },
      { id: 'Married', name: 'Casado' },
      { id: 'Divorced', name: 'Divorciado' },
    ]
    const status = reactive([
      { id: 'active', name: 'Activo' },
      { id: 'inactive', name: 'Inactivo' },
      { id: 'deleted', name: 'Eliminado' },
    ])

    // account settings data
    const accountSettingData = reactive({
      userApplicationId: '',
      username: '',
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      identification: '',
      identificationType: '',
      permissions: [],
      profileResponses: [],
      status: 'Active',
      areaId: '',
      area: '',
    })
    const accountSettingDataDefault = reactive({
      userApplicationId: '',
      username: '',
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      identification: '',
      identificationType: '',
      permissions: [],
      profileResponses: [],
      status: 'Active',
      areaId: '',
      area: '',
    })

    const campos = reactive({
      userName: {
        error: false,
        mensajesError: [],
      },
      firstName: {
        error: false,
        mensajesError: [],
      },
      lastName: {
        error: false,
        mensajesError: [],
      },
      identificationType: {
        error: false,
        mensajesError: [],
      },
      identification: {
        error: false,
        mensajesError: [],
      },
      email: {
        error: false,
        mensajesError: [],
      },
      phone: {
        error: false,
        mensajesError: [],
      },
      location: {
        error: false,
        mensajesError: [],
      },
      status: {
        error: false,
        mensajesError: [],
      },
      gender: {
        error: false,
        mensajesError: [],
      },
      dateOfBirth: {
        error: false,
        mensajesError: [],
      },
      maritalStatus: {
        error: false,
        mensajesError: [],
      },
    })

    const getAreas = async () => {
      loadingAreas.value = true
      const URL = `${HTTP.defaults.baseURL}identity-api/areas/all`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.length; index += 1) {
          listaAreas.push(resp.data.value[index])
        }
        loadingAreas.value = false
      }
    }

    const setArea = async () => {
      await getAreas()
      for (let index = 0; index < listaAreas.length; index += 1) {
        if (accountSettingData.areaId === listaAreas[index].id) {
          accountSettingData.area = listaAreas[index].name
        }
      }
    }

    // getAreas()

    const getCurrentUser = async () => {
      loading.value = true
      const URL = `${HTTP.defaults.baseURL}identity-api/user/me`
      try {
        const resp = await HTTP.get(URL)
        if (resp.status === 200) {
          accountSettingData.userApplicationId = resp.data.userApplicationId
          accountSettingData.username = resp.data.userName
          accountSettingData.firstName = resp.data.firstName
          accountSettingData.lastName = resp.data.lastName
          accountSettingData.fullName = resp.data.fullName
          accountSettingData.phone = resp.data.phone
          accountSettingData.email = resp.data.email
          accountSettingData.identificationType = resp.data.identificationType
          accountSettingData.identification = resp.data.identification
          accountSettingData.status = resp.data.status
          accountSettingData.gender = resp.data.gender

          accountSettingData.permissions = resp.data.permissions
          accountSettingData.profileResponses = resp.data.profileResponses
          accountSettingData.areaId = resp.data.areaId
          await setArea()
        }
      } catch (error) {}
    }
    getCurrentUser()

    const resetForm = async () => {
      keyComponnent.value += 1

      accountSettingData.value = {}
      router.push({ name: 'dashboard' })
    }

    const saveItem = async () => {
      const objToSave = {
        // obj
        userName: accountSettingData.username,
        firstName: accountSettingData.firstName,
        lastName: accountSettingData.lastName,
        email: accountSettingData.email,
        identification: accountSettingData.identification,
        identificationType: accountSettingData.identificationType,
        phone: accountSettingData.phone,
        gender: accountSettingData.gender,
      }
      try {
        const URL = `${HTTP.defaults.baseURL}identity-api/manager/${accountSettingData.userApplicationId}`
        const resp = await HTTP.patch(URL, objToSave)
        if (resp.status === 200) {
          notificaciones.snackbar = true
          notificaciones.color = 'success'
          notificaciones.text = 'El registro ha sido actualizado satisfactoriamente'
        }
      } catch (error) {
        if (error.response.data.errors.UserName) {
          for (let index = 0; index < error.response.data.errors.UserName.length; index += 1) {
            campos.userName.mensajesError.push(error.response.data.errors.UserName[index])
          }
          campos.userName.error = true
        }
        if (error.response.data.errors.FirstName) {
          for (let index = 0; index < error.response.data.errors.FirstName.length; index += 1) {
            campos.firstName.mensajesError.push(error.response.data.errors.FirstName[index])
          }
          campos.firstName.error = true
        }
        if (error.response.data.errors.LastName) {
          for (let index = 0; index < error.response.data.errors.LastName.length; index += 1) {
            campos.lastName.mensajesError.push(error.response.data.errors.LastName[index])
          }
          campos.lastName.error = true
        }
        if (error.response.data.errors.IdentificationType) {
          for (let index = 0; index < error.response.data.errors.IdentificationType.length; index += 1) {
            campos.identificationType.mensajesError.push(error.response.data.errors.IdentificationType[index])
          }
          campos.identificationType.error = true
        }
        if (error.response.data.errors.Identification) {
          for (let index = 0; index < error.response.data.errors.Identification.length; index += 1) {
            campos.identification.mensajesError.push(error.response.data.errors.Identification[index])
          }
          campos.identification.error = true
        }
        if (error.response.data.errors.Email) {
          for (let index = 0; index < error.response.data.errors.Email.length; index += 1) {
            campos.imageUrl.email.push(error.response.data.errors.Email[index])
          }
          campos.email.error = true
        }
        if (error.response.data.errors.Phone) {
          for (let index = 0; index < error.response.data.errors.Phone.length; index += 1) {
            campos.phone.mensajesError.push(error.response.data.errors.Phone[index])
          }
          campos.phone.error = true
        }
        if (error.response.data.errors.Location) {
          for (let index = 0; index < error.response.data.errors.Location.length; index += 1) {
            campos.location.mensajesError.push(error.response.data.errors.Location[index])
          }
          campos.location.error = true
        }
        if (error.response.data.errors.Status) {
          for (let index = 0; index < error.response.data.errors.Status.length; index += 1) {
            campos.status.mensajesError.push(error.response.data.errors.Status[index])
          }
          campos.status.error = true
        }
        if (error.response.data.errors.Gender) {
          for (let index = 0; index < error.response.data.errors.Gender.length; index += 1) {
            campos.gender.mensajesError.push(error.response.data.errors.Gender[index])
          }
          campos.gender.error = true
        }
        if (error.response.data.errors.DateOfBirth) {
          for (let index = 0; index < error.response.data.errors.DateOfBirth.length; index += 1) {
            campos.dateOfBirth.mensajesError.push(error.response.data.errors.DateOfBirth[index])
          }
          campos.dateOfBirth.error = true
        }
        if (error.response.data.errors.MobileId) {
          for (let index = 0; index < error.response.data.errors.MobileId.length; index += 1) {
            campos.mobileId.mensajesError.push(error.response.data.errors.MobileId[index])
          }
          campos.mobileId.error = true
        }
        if (error.response.data.errors.MaritalStatus) {
          for (let index = 0; index < error.response.data.errors.MaritalStatus.length; index += 1) {
            campos.maritalStatus.mensajesError.push(error.response.data.errors.MaritalStatus[index])
          }
          campos.maritalStatus.error = true
        }
      }
    }

    const errorMsgUserName = computed(() => (campos.userName.error ? campos.userName.mensajesError : []))
    const errorMsgFirstName = computed(() => (campos.firstName.error ? campos.firstName.mensajesError : []))
    const errorMsgLastName = computed(() => (campos.lastName.error ? campos.lastName.mensajesError : []))
    const errorMsgIdentificationType = computed(() => (campos.identificationType.error ? campos.identificationType.mensajesError : []))
    const errorMsgIdentification = computed(() => (
      campos.identification.error ? campos.identification.mensajesError : []
    ))
    const errorMsgEmail = computed(() => (
      campos.email.error ? campos.email.mensajesError : []
    ))
    const errorMsgPhone = computed(() => (
      campos.phone.error ? campos.phone.mensajesError : []
    ))
    const errorMsgLocation = computed(() => (
      campos.location.error ? campos.location.mensajesError : []
    ))
    const errorMsgStatus = computed(() => (
      campos.status.error ? campos.status.mensajesError : []
    ))
    const errorMsgGender = computed(() => (
      campos.gender.error ? campos.gender.mensajesError : []
    ))
    const errorMsgDateOfBirth = computed(() => (
      campos.dateOfBirth.error ? campos.dateOfBirth.mensajesError : []
    ))
    const errorMsgMaritalStatus = computed(() => (
      campos.maritalStatus.error ? campos.maritalStatus.mensajesError : []
    ))

    return {
      formTitulo,
      tabs,
      items,
      status,
      genero,
      estadoCivil,
      campos,
      tiposIdentificacion,
      accountSettingData,
      accountSettingDataDefault,
      listaAreas,
      loading,
      notificaciones,
      keyComponnent,
      menu2: false,
      icon: {
        mdiCalendar,
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
      getCurrentUser,

      // getAreas,
      // setArea,
      resetForm,
      saveItem,

      // Errors Computed
      errorMsgUserName,
      errorMsgLastName,
      errorMsgFirstName,
      errorMsgIdentificationType,
      errorMsgIdentification,
      errorMsgEmail,
      errorMsgPhone,
      errorMsgLocation,
      errorMsgStatus,
      errorMsgGender,
      errorMsgDateOfBirth,
      errorMsgMaritalStatus,
    }
  },
}
</script>
